@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#0F0D24;
}
.code{
  font-family: Poppins, sans-serif;
  font-family: 'Roboto Slab', serif;
  text-size:45px;
  color:white;
  line-height:1.5;
}
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0F0D24;
  border-radius: 20px;
  border:1px solid #fff;
  height:25vh;
}
.header{
  background:radial-gradient(#3989E8,#1E1E1E);
  width:100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top:1.5rem;
  padding-left:5rem;
  padding-right:5rem;
}
.header-two{
  background:-webkit-gradient(radial-gradient(#3989E8,#1E1E1E));
  width:100%;
}
.header-two::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  background:-webkit-gradient(linear-gradient(to bottom,#3989E8,#1E1E1E));
}
.header-two-img{
  width: 50%;
  -webkit-border-top-right-radius:15px;
  -webkit-border-top-left-radius:15px;
  -moz-border-top-left-radius:15px;
  -moz-border-top-right-radius:15px;
  background:linear-gradient(to bottom,#3989E8,#0F0D24);
  height:100vh;
}
input[type=text],[type=email],[type=number]{
  width:100%;
  padding-left: 1.25rem/* 36px */;
  padding-right: 1.25rem/* 36px */;
  height: 3.5rem/* 56px */;
}
input[type=number]::-webkit-inner-spin-button::-moz-inner-spin-buttton {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (min-width:667px) and (max-width:1024px){
  .realtime_text h1{
    font-size:29px;
    margin-top:4%;
  }
  .realtime_text img{
    width:5%;
  }
  .get_real{
    font-size:16px;
  }
  .metawork_write h2{
    font-size:12px;
  }
  .tablet_display{
    display:grid;
    justify-content:center;
    height:auto;
  }
  .tabletsign_display{
    width:100%;
    text-align:center;
  }
  .tabletsign_display{
    margin-left:0;
  }
  .robot-img{
    width:45%;
    height:auto;
    display:grid;
    justify-content:center;
    margin-left:24%;
    margin-top:50%;
  } 
  .robot-img img{
    width:100%;
    margin-left:auto;
    margin-right:auto;
  }
  .border-container-design{
    display:grid;
    width:100%;
    justify-content:center;
    padding:0;
  }
  .tablet-design-one,.tablet-design-two{
    display:flex;
    width:100%;
    justify-content:space-between;
    padding-top:4%;
  }
  .border-design{
    height:auto;
    width:50%;
    margin-left:2%;
  }
  .border-design h1{
    font-size:20px;
  }
  .border-design p{
    font-size:10px;
  }
  .part-two-tab{
    display:grid;
    width:100%;
    padding:0;
  }
  .total-img-tablet-design{
    height:auto;
    width:100%;
    padding:0;
  }
  .total-img-tablet-design img{
    height:auto;
    position:absolute;
    margin-top:70%;
    margin-bottom:20%;
  }
  .total-write-tablet-design{
    width:100%;
  }
  .total-write-tablet-design p{
    font-size:15px;
  }
  .culture-tablet{
    margin-top:60%;
  }
  .part-three{
    position: relative;
    padding:0;
    display:grid;
  }
  .culturewriteup-tabletdesign{
    width:100%;
  }
  .cultureimg-tabletdesign{
    width:100%;
  }
  .tablet-design{
    margin-top:-28%;
  }
  .win-tabdesign{
    font-size:30px;
  }
  .aimtocreate-tabdesign{
    font-size:17px;
  }
  .whymetaworks-tabdesign{
    width:130%;
    height:auto;
  }
  .onetwo-tabdesign{
    width:100%;
    margin-left:2%;
  }
  .whymetaworks-tabdesign h1{
    font-size:24px;
    font-weight:extrabold;
  }
  .whymetaworks-tabdesign p{
    font-size:21px;
  }
  .seehow-tabletdesign{
    margin-top:80%;
  }
  .copyright-tablet{
    width:160%;
  }
  .metawork-tabletdesign{
    width:120%;
  }
  .twitter-tabletdesign{
    width:100%;
  }
  .copyright-tablet h2{
    font-size:16px;
  }
  .data-tabdesign{
    display:none;
  }
  .aggregate-tabdesign{
    font-size:12px;
  }
  .protectimg-tabdesign{
    width:100%;
  }
  .ready-tabdesign{
    display:grid;
    justify-content:center;
    text-align:center;
  }
  .redstart-tabdesign{
    margin-top:8%;
  }
}
.tablet-design-one,.tablet-design-two{
  display:flex;
  width:100%;
  justify-content:space-evenly;
  padding-top:4%;
}
.border-design{
  height:auto;
  width:50%;
  margin-left:1%;
}
.border-design h1{
  font-size:20px;
}
.border-design p{
  font-size:12px;
}
.part-two-tab{
  display:flex;
  width:100%;
  padding:0;
  justify-content:space-between;
}
.total-img-tablet-design{
  height:auto;
  width:50%;
  padding:0;
}
.total-img-tablet-design img{
  width:100%;
}
.total-write-tablet-design{
  width:50%;
  padding-left:7%;
}
.total-write-tablet-design h1{
  font-weight:extrabold;
}
.total-write-tablet-design p{
  font-size:16px;
}
.part-four{
  height:70vh;
  position: relative;
  padding-top:1.5rem;
  margin-top:6rem;
  margin-left:5rem;
  margin-right:5rem;
  padding-left:4rem;
  padding-right:2rem;
  background:linear-gradient(rgba(0, 0, 0, 0.5),#0F0D24), url("components/images/Rectangle 1151.png");
  -webkit-border-top-right-radius:50px;
  -webkit-border-top-left-radius:50px;
  -moz-border-top-left-radius:50px;
  -moz-border-top-right-radius:50px;
}
.part-ref{
  background:linear-gradient(rgba(0, 0, 0, 0.5),#0F0D24 200px), url("components/images/Rectangle 1151.png");
  -webkit-border-top-right-radius:25px;
  -webkit-border-top-left-radius:25px;
  -moz-border-top-left-radius:50px;
  -moz-border-top-right-radius:50px;
  height:auto;
  margin-top:40%;
  justify-content:center;
}
.part-two{
  position: relative;
  margin-top:5%;
  padding-top:1.5rem;
  padding-left:5rem;
  padding-right:5rem;
  display:flex;
  justify-content:space-between;
}
.part-three{
  margin-top:5%;
  position: relative;
  padding-top:1.5rem;
  padding-left:5rem;
  padding-right:5rem;
  display:flex;
  justify-content:space-between;
}
.hero{
  display:flex;
  margin-top:3%;
  justify-content:space-between;
}
.page{
  padding-top:2rem;
  padding-bottom:2rem;
  padding-left:3rem;
  padding-right:3rem;
  background:#3989E8;
}
.pages{
  padding-top:1rem;
  padding-bottom:1rem;
  padding-left:1rem;
  padding-right:1rem;
  background:#3989E8;
}
.part-four{
  height:70vh;
  position: relative;
  padding-top:1.5rem;
  margin-top:6rem;
  margin-left:5rem;
  margin-right:5rem;
  padding-left:4rem;
  padding-right:2rem;
  background:linear-gradient(rgba(0, 0, 0, 0.5),#0F0D24), url("components/images/Rectangle 1151.png");
  -webkit-border-top-right-radius:50px;
  -webkit-border-top-left-radius:50px;
  -moz-border-top-left-radius:50px;
  -moz-border-top-right-radius:50px;
}
.part-four-col{
  margin-top:6rem;
  margin-left:5rem;
  margin-right:5rem;
}
th{
  text-align:left;  
  border-bottom:2px solid blue;
  border-width:20%;

}



/*font-poppin font-bold bg-[#EFF5F6] w-[110px] h-10 rounded-lg text-center
 text-[#0F0D24] text-sm cursor-pointer relative  
 ease-in-out
.sign_btn{
  font-family:poppin;
  font-weight:bold;
  background:#EFF5F6;
  width:110px;
  height:10rem;
  border-radius:
}
*/
.metaUp{
  width:100px;
}
@keyframes blink{
  50% {
    opacity:0;
  }
  80% {
    opacity:.5;
  }
}
.meta-works-blink{
  width:100%;
  display:flex;
  justify-content:center;
  animation:blink 4s linear infinite;
}
.modalparent{
    width:100%;
    height:100vh;
    position:fixed;
    z-index:50;
}
.modalparent:after{
    content:'';
    width:100%;
    height:100vh;
    background: #000000;
    position:absolute;
    top:0;
    left:0;
    opacity:0.5;
}
.slide{
  animation:slidein 2s;
}
@keyframe slidein{
  from{
  margin-top:30%;
  }
  to{
  margin-bottom:100%;
  }
}